import {callAjaxError, convertQueryStringToJson, downloadFileTxt} from "../helper";

let downloadVia = {
    hiddenPopup: function () {
        // $(this).find('form')[0].reset();
        // $(this).find('.alert').hide().text('');
        location.reload();
    },
    submitForm: function (e){
        e.preventDefault();
        let form = $(this),
            data = convertQueryStringToJson(form.serialize()),
            alert = form.find('.alert');
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'json',
            data: data
        })
            .done(function (resp) {
                if (resp.status === 200) {
                    downloadFileTxt(resp.filename + '.txt', resp.content);
                    form[0].reset();
                    alert.html(resp.message).show().removeClass('alert-danger').addClass('alert-success');
                }else {
                    alert.html(resp.message).show().removeClass('alert-success').addClass('alert-danger');
                }
            })
            .catch(function (err) {
                callAjaxError(err)
            });
    },
    init: function (){
        if ($('#popup-download-via').length) {
            let exampleModal = document.getElementById('popup-download-via')
            exampleModal.addEventListener('hidden.bs.modal', this.hiddenPopup);
        }
        $(document).on('submit', '.form-download-via', this.submitForm);
    }
}

export default downloadVia;
