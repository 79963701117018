function handleButtonCopy(event) {
    event.preventDefault();
    let button = $(this);
    let code = button.data('copy');
    const promiseA = new Promise((resolve, reject) => {
        let copyClipboard = '<input type="text" class="copyClipboard" value="' + code + '">';
        button.after(copyClipboard);
        let $copyClipboard = $('.copyClipboard');
        $copyClipboard.select();
        document.execCommand('copy');
        $copyClipboard.remove();

        resolve();
    });
    promiseA.then((successMessage) => {
        button.attr('title', 'Đã copy: ' + code).addClass('copied');
        button.find('[class*="fa-"]').toggleClass('fa-clone fa-check text-success');
        setTimeout(() => {
            button.attr('title', 'Copy').removeClass('copied');
            button.find('[class*="fa-"]').toggleClass('fa-clone fa-check text-success');
        }, 1000)
    })
}

export default handleButtonCopy;
