import {convertStringWithoutAccentsToLowerCase} from "./helper";

let Fillow = function () {
    "use strict"
    /* Search Bar ============ */
    let screenWidth = $(window).width();
    let screenHeight = $(window).height();


    let handleNiceSelect = function () {
        if ($('.default-select').length > 0) {
            $('.default-select').niceSelect();
        }
    }

    let handlePreloader = function () {
        setTimeout(function () {
            $('#preloader').remove();
            $('#main-wrapper').addClass('show');
        }, 800);

    }

    let handleMetisMenu = function () {
        if ($('#menu').length > 0) {
            $("#menu").metisMenu();
        }
        $('.metismenu > .mm-active ').each(function () {
            if (!$(this).children('ul').length > 0) {
                $(this).addClass('active-no-child');
            }
        });
    }

    let handleAllChecked = function () {
        $("#checkAll").on('change', function () {
            $("td input, .email-list .custom-checkbox input").prop('checked', $(this).prop("checked"));
        });
    }

    let handleNavigation = function () {
        $(".nav-control").on('click', function () {

            $('#main-wrapper').toggleClass("menu-toggle");

            $(".hamburger").toggleClass("is-active");
        });
    }

    let handleCurrentActive = function () {
        for (let nk = window.location,
                 o = $("ul#menu a").filter(function () {

                     return this.href == nk;

                 })
                     .addClass("mm-active")
                     .parent()
                     .addClass("mm-active"); ;) {

            if (!o.is("li")) break;

            o = o.parent()
                .addClass("mm-show")
                .parent()
                .addClass("mm-active");
        }
    }

    let handleMiniSidebar = function () {
        $("ul#menu>li").on('click', function () {
            const sidebarStyle = $('body').attr('data-sidebar-style');
            if (sidebarStyle === 'mini') {
                console.log($(this).find('ul'))
                $(this).find('ul').stop()
            }
        })
    }

    let handleSetMiniSidebar = function () {
        let windowWidth = $(window).width();
        if (windowWidth < 1023 && windowWidth >= 768){
            $('body').attr('data-sidebar-style', 'mini')
        }else if (windowWidth < 768) {
            $('body').attr('data-sidebar-style', 'overlay')
        }else {
            $('body').attr('data-sidebar-style', 'full')
        }
    }

    let handleMinHeight = function () {
        let win_h = window.outerHeight;
        if (win_h > 0 ? win_h : screen.height) {
            $(".content-body").css("min-height", (win_h + 60) + "px");
        }
        ;
    }

    let handleDataAction = function () {
        $('a[data-action="collapse"]').on("click", function (i) {
            i.preventDefault(),
                $(this).closest(".card").find('[data-action="collapse"] i').toggleClass("mdi-arrow-down mdi-arrow-up"),
                $(this).closest(".card").children(".card-body").collapse("toggle");
        });

        $('a[data-action="expand"]').on("click", function (i) {
            i.preventDefault(),
                $(this).closest(".card").find('[data-action="expand"] i').toggleClass("icon-size-actual icon-size-fullscreen"),
                $(this).closest(".card").toggleClass("card-fullscreen");
        });


        $('[data-action="close"]').on("click", function () {
            $(this).closest(".card").removeClass().slideUp("fast");
        });

        $('[data-action="reload"]').on("click", function () {
            let e = $(this);
            e.parents(".card").addClass("card-load"),
                e.parents(".card").append('<div class="card-loader"><i class=" ti-reload rotate-refresh"></div>'),
                setTimeout(function () {
                    e.parents(".card").children(".card-loader").remove(),
                        e.parents(".card").removeClass("card-load")
                }, 2000)
        });
    }

    let handleHeaderHight = function () {
        const headerHight = $('.header').innerHeight();
        $(window).scroll(function () {
            if ($('body').attr('data-layout') === "horizontal" && $('body').attr('data-header-position') === "static" && $('body').attr('data-sidebar-position') === "fixed")
                $(this.window).scrollTop() >= headerHight ? $('.dlabnav').addClass('fixed') : $('.dlabnav').removeClass('fixed')
        });
    }

    let handleDzScroll = function () {
        $('.dlab-scroll').each(function () {
            let scroolWidgetId = $(this).attr('id');
            const ps = new PerfectScrollbar('#' + scroolWidgetId, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20
            });
            ps.isRtl = false;
        })
    }

    let handleMenuTabs = function () {
        if (screenWidth <= 991) {
            $('.menu-tabs .nav-link').on('click', function () {
                if ($(this).hasClass('open')) {
                    $(this).removeClass('open');
                    $('.fixed-content-box').removeClass('active');
                    $('.hamburger').show();
                } else {
                    $('.menu-tabs .nav-link').removeClass('open');
                    $(this).addClass('open');
                    $('.fixed-content-box').addClass('active');
                    $('.hamburger').hide();
                }
                //$('.fixed-content-box').toggleClass('active');
            });
            $('.close-fixed-content').on('click', function () {
                $('.fixed-content-box').removeClass('active');
                $('.hamburger').removeClass('is-active');
                $('#main-wrapper').removeClass('menu-toggle');
                $('.hamburger').show();
            });
        }
    }

    let handleChatbox = function () {
        $('.bell-link').on('click', function () {
            $('.chatbox').addClass('active');
        });
        $('.chatbox-close').on('click', function () {
            $('.chatbox').removeClass('active');
        });
    }

    let handlePerfectScrollbar = function () {
        if ($('.dlabnav-scroll').length > 0) {
            //const qs = new PerfectScrollbar('.dlabnav-scroll');
            const qs = new PerfectScrollbar('.dlabnav-scroll');

            qs.isRtl = false;
        }
    }

    let handleBtnNumber = function () {
        $('.btn-number').on('click', function (e) {
            e.preventDefault();

            let fieldName = $(this).attr('data-field');
            let type = $(this).attr('data-type');
            let input = $("input[name='" + fieldName + "']");
            let currentVal = parseInt(input.val());
            if (!isNaN(currentVal)) {
                if (type == 'minus')
                    input.val(currentVal - 1);
                else if (type == 'plus')
                    input.val(currentVal + 1);
            } else {
                input.val(0);
            }
        });
    }

    let handleDzChatUser = function () {
        $('.dlab-chat-user-box .dlab-chat-user').on('click', function () {
            $('.dlab-chat-user-box').addClass('d-none');
            $('.dlab-chat-history-box').removeClass('d-none');
            //$(".chatbox .msg_card_body").height(vHeightArea());
            //$(".chatbox .msg_card_body").css('height',vHeightArea());
        });

        $('.dlab-chat-history-back').on('click', function () {
            $('.dlab-chat-user-box').removeClass('d-none');
            $('.dlab-chat-history-box').addClass('d-none');
        });

        $('.dlab-fullscreen').on('click', function () {
            $('.dlab-fullscreen').toggleClass('active');
        });

        /* var vHeight = function(){ */

        /* } */


    }


    let handleDzFullScreen = function () {
        $('.dlab-fullscreen').on('click', function (e) {
            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                /* Enter fullscreen */
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen(); /* IE/Edge */
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen(); /* Firefox */
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen(); /* Chrome, Safari & Opera */
                }
            } else { /* exit fullscreen */
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen();
                }
            }
        });
    }

    let handleshowPass = function () {
        $('.show-pass').on('click', function () {
            $(this).toggleClass('active');
            if ($('#dlab-password').attr('type') == 'password') {
                $('#dlab-password').attr('type', 'text');
            } else if ($('#dlab-password').attr('type') == 'text') {
                $('#dlab-password').attr('type', 'password');
            }
        });
    }

    let heartBlast = function () {
        $(".heart").on("click", function () {
            $(this).toggleClass("heart-blast");
        });
    }

    let handleDzLoadMore = function () {
        $(".dlab-load-more").on('click', function (e) {
            e.preventDefault();	//STOP default action
            $(this).append(' <i class="fas fa-sync"></i>');

            let dlabLoadMoreUrl = $(this).attr('rel');
            let dlabLoadMoreId = $(this).attr('id');

            $.ajax({
                method: "POST",
                url: dlabLoadMoreUrl,
                dataType: 'html',
                success: function (data) {
                    $("#" + dlabLoadMoreId + "Content").append(data);
                    $('.dlab-load-more i').remove();
                }
            })
        });
    }

    let handleLightgallery = function () {
        if ($('#lightgallery').length > 0) {
            $('#lightgallery').lightGallery({
                loop: true,
                thumbnail: true,
                exThumbImage: 'data-exthumbimage'
            });
        }
    }
    let handleCustomFileInput = function () {
        $(".custom-file-input").on("change", function () {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    }

    let vHeight = function () {
        var ch = $(window).height() - 206;
        $(".chatbox .msg_card_body").css('height', ch);
    }

    let domoPanel = function () {
        const ps = new PerfectScrollbar('.dlab-demo-content');
        $('.dlab-demo-trigger').on('click', function () {
            $('.dlab-demo-panel').addClass('show');
        });
        $('.dlab-demo-close, .bg-close').on('click', function () {
            $('.dlab-demo-panel').removeClass('show');
        });

        $('.dlab-demo-bx').on('click', function () {
            $('.dlab-demo-bx').removeClass('demo-active');
            $(this).addClass('demo-active');
        });
    }

    let handleDatetimepicker = function () {
        if ($("#datetimepicker1").length > 0) {
            $('#datetimepicker1').datetimepicker({
                inline: true,
            });
        }
    }

    let handleSelect2 = function () {
        if ($(".select2").length > 0) {
            $('.select2').select2();
        }

        if ($(".select2-no-search").length > 0) {
            $('.select2-no-search').select2({
                minimumResultsForSearch: -1
            });
        }

        if ($(".select2-choose-locales").length > 0) {
            $('.select2-choose-locales').select2({
                templateResult: function (item) {
                    let icon = `/images/locales/${convertStringWithoutAccentsToLowerCase(item.text)}.png`,
                        $span = $('<span class="d-flex align-items-center"><img src="'+icon+'" style="width: 20px; margin-right: 8px"/>' + item.text + '</span>');
                    return $span;
                },
                templateSelection: function (item) {
                    let icon = '/images/locales/' + convertStringWithoutAccentsToLowerCase(item.text) + '.png',
                        $span = $('<span class="d-flex align-items-center"><img src="'+icon+'" style="width: 20px; margin-right: 8px"/>' + item.text + '</span>');
                    return $span;
                }
            });
        }
    }

    let handleCkEditor = function () {
        if ($("#ckeditor").length > 0) {
            ClassicEditor
                .create(document.querySelector('#ckeditor'), {
                    // toolbar: [ 'heading', '|', 'bold', 'italic', 'link' ]
                })
                .then(editor => {
                    window.editor = editor;
                })
                .catch(err => {
                    console.error(err.stack);
                });
        }
    }

    let handleMenuPosition = function () {

        if (screenWidth > 1024) {
            $(".metismenu  li").unbind().each(function (e) {
                if ($('ul', this).length > 0) {
                    var elm = $('ul:first', this).css('display', 'block');
                    var off = elm.offset();
                    var l = off.left;
                    var w = elm.width();
                    var elm = $('ul:first', this).removeAttr('style');
                    var docH = $("body").height();
                    var docW = $("body").width();

                    if ($('html').hasClass('rtl')) {
                        var isEntirelyVisible = (l + w <= docW);
                    } else {
                        var isEntirelyVisible = (l > 0) ? true : false;
                    }

                    if (!isEntirelyVisible) {
                        $(this).find('ul:first').addClass('left');
                    } else {
                        $(this).find('ul:first').removeClass('left');
                    }
                }
            });
        }
    }

    let handleDraggableCard = function () {
        var dzCardDraggable = function () {
            return {
                //main function to initiate the module
                init: function () {
                    var containers = document.querySelectorAll('.draggable-zone');

                    if (containers.length === 0) {
                        return false;
                    }

                    var swappable = new Sortable.default(containers, {
                        draggable: '.draggable',
                        handle: '.draggable.draggable-handle',
                        mirror: {
                            appendTo: 'body',
                            constrainDimensions: true
                        }

                    });
                    swappable.on('drag:stop', () => {
                        setTimeout(function () {
                            setBoxCount();
                        }, 200);

                    })
                }
            };
        }();

        $(document).ready(function () {
            dzCardDraggable.init();
        });


        function setBoxCount() {
            let cardCount = 0;
            $('.dropzoneContainer').each(function () {
                cardCount = $(this).find('.draggable-handle').length;
                $(this).find('.totalCount').html(cardCount);
            });
        }
    }

    /* Function ============ */
    return {
        init: function () {
            handleMetisMenu();
            handleSetMiniSidebar();
            handleAllChecked();
            handleNavigation();
            handleCurrentActive();
            handleMiniSidebar();
            handleMinHeight();
            handleDataAction();
            handleHeaderHight();
            handleDzScroll();
            handleMenuTabs();
            handleChatbox();
            handlePerfectScrollbar();
            handleBtnNumber();
            handleDzChatUser();
            handleDzFullScreen();
            handleshowPass();
            heartBlast();
            handleDzLoadMore();
            handleLightgallery();
            handleCustomFileInput();
            vHeight();
            // domoPanel();
            handleDatetimepicker();
            handleCkEditor();
            handleDraggableCard();
            handleSelect2();
        },


        load: function () {
            handlePreloader();
            // handleNiceSelect();
        },

        resize: function () {
            vHeight();
        },

        setMiniSidebar: function (){
            handleSetMiniSidebar()
        },

        handleMenuPosition: function () {
            handleMenuPosition();
        },
    }

}();

export default Fillow;
