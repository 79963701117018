function deleteData(e) {
    e.preventDefault();
    let btn = $(this),
        href = btn.attr('href'),
        msgTitle = btn.data('title'),
        msgDesc = '<table class="table table-borderless"><tr><td>' + btn.data('desc').replaceAll('|', '</td><td>') + '</td></tr><table>';
    Swal.fire({
        icon: 'question',
        title: msgTitle,
        html: msgDesc,
        showDenyButton: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Huỷ',
        denyButtonText: 'Xoá',
    }).then((result) => {
        if (result.isDenied) {
            window.location.href = href;
        }
    })
}

export default deleteData;
