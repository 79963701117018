import {DOT} from './const';

export function formatPrice(nStr){
    nStr += '';
    let x = nStr.split(DOT);
    let x1 = x[0];
    let x2 = x.length > 1 ? DOT + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + DOT + '$2');
    }
    return x1 + x2;
}

export function caculatorTotalPriceBuy(price_str, number) {
    let price = price_str.replaceAll(DOT, '');
    return formatPrice(price * number);
}

export function convertQueryStringToJson(queryStr) {
    return JSON.parse('{"' + decodeURI(queryStr).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}

export function downloadFileTxt(filename, text) {
    let createDl = document.createElement('a');
    createDl.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    createDl.setAttribute('download', filename);
    createDl.style.display = 'none';
    document.body.appendChild(createDl);
    createDl.click();
    document.body.removeChild(createDl);
}

export function callAjaxError(err) {
    console.log(err);
    switch (err.status){
        case 401:
            window.location.href = '/dang-nhap'
            break;
        case 419:
            Swal.fire({
                icon: 'error',
                title: 'Hết phiên làm việc! Nhấn Crl+F5 để làm mới!',
                confirmButtonText: 'Làm mới',
                confirmButtonColor: '#2290FF',
            }).then((result)=>{
                if (result.isConfirmed){
                    location.reload();
                }
            })
            break;
        default:
            alert.text(err.responseJSON.message).show();
    }
}

export function convertStringWithoutAccentsToLowerCase(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll('-','').replaceAll(' ', '').toLowerCase()
}
