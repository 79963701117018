import Fillow from "./fillow";
import deleteData from "./actions/deleteData";
import chooseParentCategory from "./actions/chooseParentCategory";
import generateApiKey from "./actions/generateApiKey";
import handleButtonCopy from "./actions/handleButtonCopy";
import buyVia from "./actions/buyVia";
import downloadVia from "./actions/downloadVia";


$(function (){
    $('[data-bs-toggle="popover"]').popover();
    Fillow.init();
    $('[data-toggle="tooltip"]').tooltip()

    $(window).on('load', function () {
        // Fillow.load();
        // setTimeout(function () {
        //     Fillow.handleMenuPosition();
        // }, 1000);
        Fillow.handleMenuPosition();
    });

    $(window).on('resize', function () {
        Fillow.setMiniSidebar();
        Fillow.resize();
        setTimeout(function () {
            Fillow.handleMenuPosition();
        }, 1000);
    });

    $(document).on('click', '.btn-delete-data', deleteData);
    $(document).on('change', '.choose-parent-category', chooseParentCategory);
    $(document).on('click', '.btn-generate-api-key', generateApiKey);
    $(document).on('click', '.btn-copy-data', handleButtonCopy);

    $('.form-auto-submit').on('change', 'input, select', function () {
        $(this).closest('form').submit();
    })

    $('input[type="date"]').on('change', function (e){
        e.stopImmediatePropagation();
    }).focusout(function () {
        $(this).closest('form').submit();
    })

    //buy via
    buyVia.init();

    //download list Via
    downloadVia.init();

    if ($('#popup-confirm-add-via').length) {
        let popupConfirmAddVia = new bootstrap.Modal(document.getElementById('popup-confirm-add-via'), {
            backdrop: 'static',
        })
        popupConfirmAddVia.show()
    }

    $(document).ajaxStart(function() {
        $("#preloader").addClass('show');
    }).ajaxStop(function() {
        $("#preloader").removeClass('show');
    });
})
