function chooseParentCategory() {
    let select = $(this),
        val = select.val();
    let formChild = $('.form-category-child');
    if (formChild.length) {
        if (val !== '0') {
            formChild.slideDown()
        }else {
            formChild.slideUp()
        }
    }
}
export default chooseParentCategory;
