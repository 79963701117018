import {caculatorTotalPriceBuy, convertQueryStringToJson, callAjaxError} from "../helper";

let buyVia = {
    showPopup: function (event) {
        let button = event.relatedTarget,
            userID = $(button).attr('data-user'),
            cateID = $(button).attr('data-cate'),
            boxCate = $(button.closest('.box-cate-via')),
            name = boxCate.find('.cate-via-name').text(),
            localImg = boxCate.find('.cate-via-local-logo').attr('src'),
            price = boxCate.find('.cate-via-price').text(),
            numberRemain = boxCate.find('.cate-via-remain').text();

        let modal = $(this);
        modal.find('.cate-via-name').text(name);
        modal.find('.cate-via-local-logo').attr('src', localImg);
        modal.find('.cate-via-remain').text(numberRemain);
        modal.find('.cate-via-price').text(price);
        modal.find('.quantity').attr('max', numberRemain);
        modal.find('.total-price').text(caculatorTotalPriceBuy(price, 1));
        modal.find('[name="user_id"]').val(userID);
        modal.find('[name="category_id"]').val(cateID);
    },
    hiddenPopup: function () {
        let modal = $(this);
        modal.find('.alert').hide();
    },
    changeQuantity: function () {
        let input = $(this),
            price = input.closest('form').find('.cate-via-price').text(),
            totalPrice = caculatorTotalPriceBuy(price, input.val());
        $('.total-price').text(totalPrice)
    },
    submitForm: function (e) {
        e.preventDefault();
        let form = $(this),
            data = convertQueryStringToJson(form.serialize()),
            alert = form.find('.alert');
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'json',
            data: data
        })
            .done(function (resp) {
                if (resp.status === 200) {
                    form.find('.btn-close').trigger('click')
                    Swal.fire({
                        icon: 'success',
                        title: resp.message,
                        confirmButtonText: 'Danh sách đã mua',
                        confirmButtonColor: '#adb5bd',
                        showCancelButton: true,
                        cancelButtonText: 'Xác nhận',
                        cancelButtonColor: '#2290FF',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/account/lich-su-mua-hang';
                        }else {
                            location.reload();
                        }
                    })
                } else if (resp.status === 500) {
                    form.submit();
                } else {
                    alert.text(resp.message).show();
                }
            })
            .catch(function (err) {
                callAjaxError(err)
            });
    },
    init: function () {
        if ($('#popup-buy-via').length) {
            let exampleModal = document.getElementById('popup-buy-via')
            exampleModal.addEventListener('show.bs.modal', this.showPopup);
            exampleModal.addEventListener('hidden.bs.modal', this.hiddenPopup);
        }
        $('.form-buy-via').on('change keyup', '.quantity', this.changeQuantity);
        $(document).on('submit', '.form-buy-via', this.submitForm);
    }
}

export default buyVia;
